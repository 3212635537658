import { SubscriptionStatusGroup } from '@wix/ambassador-billing-v1-subscription/types';
import { customerCountSubscriptions } from '@wix/ambassador-billing-v1-subscription/http';
import { listPaymentAgreements } from '@wix/ambassador-payments-saved-payment-methods-v1-payment-agreement/http';
import {
  ListPaymentAgreementsResponse,
  PaymentAgreement,
} from '@wix/ambassador-payments-saved-payment-methods-v1-payment-agreement/types';
import { ViewerScriptContext } from '../../../viewer.app';
import { PublicApiErrorMessages } from '../../../enums';
import { HttpResponse } from '@wix/http-client';

// functions reurned from here are exposed to other teams use, changes in them is dangerous and can break others
export const getPublicApi = (context: ViewerScriptContext) => {
  const getNumLiveSubscriptionsBySavedPaymentMethodId = async (
    paymentMethodId: string,
  ): Promise<{ amount?: number; error?: PublicApiErrorMessages }> => {
    const listPaymentAgreementsRes = await _getPaymentAgreementsByPaymentMethod(context, paymentMethodId);
    if (listPaymentAgreementsRes?.data.paymentAgreements) {
      const agreementIds = _mapToArrayOfAgreementIds(listPaymentAgreementsRes.data.paymentAgreements);
      return _countLiveSubscriptionsWithOneOfAgreements(agreementIds, context);
    } else {
      throw new Error(PublicApiErrorMessages.ERROR_CALLING_LIST_AGREEMENTS);
    }
  };
  return { getNumLiveSubscriptionsBySavedPaymentMethodId };
};

const _stringDefined = (value: string | null | undefined): value is string => {
  return value !== null && value !== undefined;
};

const _mapToArrayOfAgreementIds = (paymentAgreements: PaymentAgreement[]): string[] => {
  return paymentAgreements.map((paymentAgreement) => paymentAgreement.id).filter(_stringDefined);
};

const _getPaymentAgreementsByPaymentMethod = async (
  context: ViewerScriptContext,
  paymentMethodId: string,
): Promise<HttpResponse<ListPaymentAgreementsResponse> | undefined> => {
  return context.httpClient?.request(listPaymentAgreements({ savedPaymentMethodId: paymentMethodId })).catch((e) => {
    context.errorMonitor?.addBreadcrumb({
      message: 'encountered error when calling listAgreements in delete payment method flow',
    });
    context.errorMonitor?.captureException(e);
    return undefined;
  });
};

const _countLiveSubscriptionsWithOneOfAgreements = async (
  agreementIds: string[],
  context: ViewerScriptContext,
): Promise<{
  amount: number;
}> => {
  let listOfLiveCountsPerAgreement: (number | undefined)[] = [];
  try {
    listOfLiveCountsPerAgreement = await Promise.all(
      agreementIds.map(async (agreementId) => {
        const response = await context.httpClient?.request(
          customerCountSubscriptions({
            filter: { paymentMethodId: agreementId, statusGroup: SubscriptionStatusGroup.LIVE },
          }),
        );
        return response?.data.total;
      }),
    );
  } catch (error: any) {
    context.errorMonitor?.addBreadcrumb({
      message: 'encountered error when calling customerCountSubscriptions in delete payment method flow',
    });
    context.errorMonitor?.captureException(error);
    throw new Error(PublicApiErrorMessages.ERROR_CALLING_BASS_API);
  }
  return _sumCountsArray(context, listOfLiveCountsPerAgreement);
};

const _sumCountsArray = (context: ViewerScriptContext, listOfLiveCountsPerAgreement: (number | undefined)[]) => {
  if (!listOfLiveCountsPerAgreement.includes(undefined)) {
    const sum = (listOfLiveCountsPerAgreement as number[]).reduce(
      (accumultatedSum, currentNum) => accumultatedSum + currentNum,
      0,
    );
    return { amount: sum };
  } else {
    context.errorMonitor?.addBreadcrumb({
      message: `listOfLiveCountsPerAgreement value was ${JSON.stringify(listOfLiveCountsPerAgreement)}`,
    });
    context.errorMonitor?.captureMessage('count API returned an error during delete payment method flow');
    throw new Error(PublicApiErrorMessages.ERROR_CALLING_BASS_API);
  }
};
