export enum CreditCardNetwork {
  Amex = 'amex',
  Dankort = 'dankort',
  Diners = 'diners',
  DinersClub = 'dinersclub',
  Discover = 'discover',
  Forbrugsforeningen = 'forbrugsforeningen',
  Isracard = 'isracard',
  JCB = 'jcb',
  Maestro = 'maestro',
  MasterCard = 'mastercard',
  RuPay = 'rupay',
  Troy = 'troy',
  UnionPay = 'unionpay',
  Visa = 'visa',
  VisaElectron = 'visaelectron',
  Elo = 'elo',
  Hipercard = 'hipercard',
}

export const PAGE_SIZE = 50;

export const enum PublicApiErrorMessages {
  ERROR_CALLING_BASS_API = 'Bass Api returned error when counting the subscriptions',
  ERROR_CALLING_LIST_AGREEMENTS = 'list-agreements Api returned error when calculated the amount',
  INTERNAL_CALCULATION_ERROR = 'crashed when tried to sum the live subscriptions of all agreements',
}
