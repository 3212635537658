import { getPublicApi } from './components/MySubscriptions/PublicApi/viewerPublicApi';
import { InitAppForPageFn, IHttpClient } from '@wix/yoshi-flow-editor';
import { ErrorMonitor } from '@wix/yoshi-flow-editor/error-monitor';

export interface ViewerScriptContext {
  httpClient?: IHttpClient | undefined;
  errorMonitor?: ErrorMonitor | undefined;
}
const mySubscriptionsViewerContext: ViewerScriptContext = {};

export const initAppForPage: InitAppForPageFn = async (
  _initParams,
  _platformApis,
  _wixCodeApi,
  _platformServicesApis,
  flowAPI,
) => {
  mySubscriptionsViewerContext.httpClient = flowAPI.httpClient;
  mySubscriptionsViewerContext.errorMonitor = flowAPI.errorMonitor;
  return {};
};

// This exponses public APIs to other viewer apps, **don't delete this**!
export const exports = getPublicApi(mySubscriptionsViewerContext);
